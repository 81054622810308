<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3>Push Notification</h3>
        <h4>{{ status | capitalize }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="7">
        <v-form ref="form" v-model="formIsValid" :lazy-validation="false">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Subject"
                placeholder="Best in OC joined TakeIn"
                v-model="subject"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Headline"
                placeholder="All you can eat Sushi in OC"
                v-model="headline"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Image Url"
                v-model="image_url"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="message_type"
                :items="['', 'producer', 'product']"
                label="Select Type"
                hint="Deep link to this type when user open the message"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :disabled="!message_type || message_type == ''"
                label="Handle or Slug"
                hint="Get from takein.com"
                @blur="objectLookup"
                v-model="object_lookup"
                :append-icon="
                  object_lookup && !object_message ? 'mdi-check' : ''
                "
                :error-messages="object_message"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!uid">
            <v-col cols="12" md="6">
              <v-select
                v-if="cities"
                v-model="city"
                :items="cities"
                item-text="city_name"
                item-value="city_code"
                label="Select City"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :disabled="!city || !city.city_code"
                v-model="range"
                :items="[5, 10, 15, 20, 30, 40]"
                label="Select Range"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Test User Email"
                v-model="email"
                :error-messages="user_message"
                @blur="userLookup"
                :append-icon="uid && !user_message ? 'mdi-check' : ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!uid">
              <v-select
                v-model="profile_type"
                :items="['all', 'producer', 'consumer']"
                label="Profile"
                hint="Sends message to ONLY given profile types only"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="status"
                :items="['draft', 'send']"
                @change="checkSendStatus"
                label="Status"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" offset-sm="1" sm="4">
        <v-row>
          <v-img src="/img/android.png">
            <v-container class="fill-height" fluid>
              <v-row align="center" class="mx-6 py-2 white">
                <v-sheet outlined height="50" class="white">
                  <v-col cols="12" class="title"> {{ subject }}</v-col>
                  <v-col cols="12"> {{ headline }}</v-col>
                </v-sheet>
              </v-row>
            </v-container>
          </v-img>
        </v-row>
        <v-row v-if="image_url" class="mt-5 pt-4">
          <v-sheet elevation="1" width="100%">
            <v-col cols="12"
              ><span class="body-1">{{ subject }}</span></v-col
            >
            <v-col cols="12"
              ><span class="body-2">{{ headline }}</span></v-col
            >
            <v-col cols="12"> <v-img contain :src="image_url"> </v-img></v-col>
          </v-sheet>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="status === 'send'">
      <v-col>
        <v-checkbox v-model="agreed">
          <template v-slot:label>
            <div>
              I confirm to send push notification
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="10" md="4">
        <v-btn
          color="success"
          :disabled="!formIsValid || !agreed"
          @click="save"
          class="mr-4"
          >Save</v-btn
        >

        <v-btn text to="/admin/marketing/messages">Back</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      id: null,
      formIsValid: false,
      subject: null,
      headline: null,
      cities: [],
      uid: null,
      email: null,
      user_message: null,
      city: null,
      range: 15,
      message_type: null,
      image_url: null,
      message_id: null,
      object_lookup: null,
      object_message: null,
      showConfirm: false,
      agreed: true,
      status: 'draft',
      profile_type: null,
      rules: {
        required: value => !!value || 'Value Required'
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      operatingCities: 'operatingCities'
    })
  },
  mounted() {
    console.log('push id:', this.$route.params.id)
    this.id = this.$route.params.id
    if (this.id) {
      this.$store.dispatch('loadPushMessage', this.id).then(message => {
        if (message) {
          this.subject = message.subject
          this.headline = message.headline
          this.status = message.status
          this.message_type = message.message_type
          this.message_id = message.message_id
          this.object_lookup = message.object_lookup
          this.image_url = message.image_url
          this.range = message.range
          this.uid = message.uid
          this.email = message.email
          this.profile_type = message.profile_type
          if (message.operating_city) {
            this.city = {
              city_name: message.city_name,
              city_code: message.operating_city
            }
          }
          // city
        } else {
          this.$store.dispatch('setError', { message: 'message not found' })
        }
      })
    }
    this.$store.dispatch('getOperatingCities').then(() => {
      // gets city names from cities object
      this.cities = this.operatingCities
      this.cities.unshift({ city_name: '' })
      /*if (this.cities.length > 0) {
        this.city = this.cities[0]
      }*/
    })
  },
  methods: {
    async userLookup() {
      if (!this.email || this.email.length == 0) {
        this.user_message = null
        this.uid = null
        return
      }
      const user = await this.$store.dispatch('getProfileByEmail', this.email)
      if (!user) {
        this.user_message = 'not found'
      } else {
        this.user_message = null
        this.uid = user.uid
      }
    },

    async objectLookup() {
      if (!this.object_lookup || this.object_lookup.length == 0) {
        return
      }
      console.log(`Lookup ${this.message_type} for :`, this.object_lookup)
      switch (this.message_type) {
        case 'producer':
          const user = await this.$store.dispatch(
            'getProfileByHandle',
            this.object_lookup
          )

          if (!user) {
            this.object_message = 'not found'
          } else {
            this.object_message = null
            this.message_id = user.uid
          }
          break
        case 'product':
          const data = await this.$store.dispatch(
            'getProductBySlug',
            this.object_lookup
          )
          if (!data) {
            this.object_message = 'not found'
          } else {
            this.object_message = null
            this.message_id = data.id
          }
          break

        default:
          break
      }
    },
    save() {
      if (this.status === 'send') {
        this.showConfirm = true
      }
      this.$store.dispatch('savePushMessages', {
        id: this.id,
        subject: this.subject,
        headline: this.headline,
        status: this.status,
        message_type: this.message_type,
        message_id: this.message_id,
        object_lookup: this.object_lookup,
        image_url: this.image_url,
        profile_type: this.profile_type,
        range: this.range,
        uid: this.uid,
        email: this.email,
        operating_city: this.city ? this.city.city_code : null,
        city_name: this.city ? this.city.city_name : null
      })
      this.$store.dispatch('setMessage', { title: 'Saved' })
    },
    checkSendStatus() {
      if (this.status === 'send') {
        this.agreed = false
      } else {
        this.agreed = true
      }
    }
  }
}
</script>
